.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background: url(https://www.tosito.se/wp-content/uploads/2020/11/Tosito_Sjoviken_Exterior_Ovan_2011-web-1024x768.jpg);
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
}

.box {
  padding: 1rem;
  background-color: #282c34cc;
  border-radius: 1rem;
}
